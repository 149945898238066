import { LazyImage } from "react-lazy-images";
import { styles } from "./Item.style";

export interface IItemProps {
  image: string;
  label: string;
  text: string;
}

export function Item({ image, label, text }) {
  return (
    <div css={styles.divPoint}>
      <LazyImage
        src={image}
        alt=""
        placeholder={({ ref }) => <div ref={ref} />}
        actual={({ imageProps }) => <img alt="" title="" css={styles.icon} {...imageProps} />}
      />
      <div>
        <div css={styles.title}>{label}</div>
        <p>{text}</p>
      </div>
    </div>
  );
}
